<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    Header(:content="headerContent")
    div(v-if="uid").wrap-create-meeting.pb100
      ModuleSetCondition(
        ref="set_condition"
        :meetingToTakeOver="meetingToTakeOver"
        @setSummary="setSummary"
        @validateCurrentSettings="validateCurrentSettings")
      ModuleEditMember(
        ref="edit_member"
        :meetingToTakeOver="meetingToTakeOver"
        @validateCurrentSettings="validateCurrentSettings")
      ModuleSetSummary(ref="set_summary"
        @validateCurrentSettings="validateCurrentSettings")
      //- div(v-show="showSetCondition").wrap-set-condition.py20
      //-   ModuleSetCondition(ref="set_condition"
      //-     @setPreventNext="setPreventNext")
      //- div(v-show="!showSetCondition").f.fc
      //-   span 詳細設定
      ModuleSelectMeetingRoomType(ref="select_meeting_room_type"
        @validateCurrentSettings="validateCurrentSettings")
      div(@click="onNext").wrap-invite-btn.f.fh
        div(:style="`background: ${btnBgColor};`").invite-btn-content.py12
          span.block.text-center.mr4 日時候補の調整へ進む
    NowLoading(:show="showNowLoading")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-create-meeting {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-invite-btn {
    z-index: 1;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    .invite-btn-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      cursor: pointer;
      // background: $active_color;
      border-radius: 28px;
      filter: $drop_shadow;
      span {
        color: $dark_primary_text_color;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import NowLoading from '@/components/shared/NowLoading'
import ModuleSetSummary from '@/components/module/create-meeting/ModuleSetSummary'
import ModuleEditMember from '@/components/module/create-meeting/ModuleEditMember3'
import ModuleSetCondition from '@/components/module/create-meeting/ModuleSetCondition'
import ModuleSelectMeetingRoomType from '@/components/module/create-meeting/ModuleSelectMeetingRoomType'
// import ModuleCreateMeeting from '@/components/module/ModuleCreateMeeting'

export default {
  components: {
    Auth,
    Header,
    NowLoading,
    ModuleSetSummary,
    ModuleSetCondition,
    ModuleEditMember,
    ModuleSelectMeetingRoomType
  },
  data() {
    return {
      headerContent: {
        title: 'ミーティングを作成',
        left: {
          icon: 'mdi-chevron-left',
          action: this.onBack
        }
        // right: {
        //   label: 'OK',
        //   color: '#4285f4',
        //   action: this.onNext
        // }
      },
      meetingToTakeOver: null,
      preventNext: true,
      showNowLoading: false,
      showSetCondition: false,
      btnBgColor: '#999'
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    preventNext() {
      this.btnBgColor = this.preventNext ? '#999' : '#4285f4'
    }
  },
  async mounted() {
    if (this.$route.params.meetingIdToTakeOver) {
      this.meetingToTakeOver = await database
        .meetingCollection()
        .findById(this.$route.params.meetingIdToTakeOver)
      console.log('this.meetingToTakeOver', this.meetingToTakeOver)
    }

    const moduleSetSummary = this.$refs.set_summary
    const moduleEditMember = this.$refs.edit_member
    const moduleSetCondition = this.$refs.set_condition
    this.setSummary({
      title: `${moduleSetSummary.currentConditionTemplate}のミーティング`,
      about: moduleSetSummary.conditionText
    })

    // ミーティングの条件を引き継ぐ
    if (this.meetingToTakeOver) {
      this.setSummary({
        title: this.meetingToTakeOver.title,
        about: this.meetingToTakeOver.about
      })
      moduleEditMember.forms = this.meetingToTakeOver.attendee.map((a) => {
        if (a.email) return { value: a.email, isValid: true, isDuplicated: false }
        if (a.telNo) return { value: a.telNo, isValid: true, isDuplicated: false }
        if (a.name) return { value: a.name, isValid: true, isDuplicated: false }
      })
      moduleSetCondition.setDefaultConditionByMeeting(this.meetingToTakeOver)
    }
  },
  methods: {
    validateCurrentSettings() {
      const moduleSetSummary = this.$refs.set_summary
      const moduleEditMember = this.$refs.edit_member
      const moduleSetCondition = this.$refs.set_condition
      if (
        moduleEditMember.getMember()[0] &&
        moduleEditMember.allMemberIsValid() &&
        moduleSetSummary.title !== '' &&
        moduleSetSummary.title !== null &&
        moduleSetCondition.duration > 0 &&
        moduleSetCondition.dayOfWeek.filter((e) => e.active)[0] &&
        (!moduleSetCondition.$refs.dateRangePicker ||
          !moduleSetCondition.$refs.dateRangePicker.isInvalidRange) &&
        (!moduleSetCondition.$refs.timePicker ||
          !moduleSetCondition.$refs.timePicker.isInvalidRange)
      ) {
        this.preventNext = false
      } else {
        this.preventNext = true
      }
    },
    setSummary({ title, about }) {
      let moduleSetSummary = this.$refs.set_summary
      if (!moduleSetSummary.titleIsManuallyEdited) moduleSetSummary.title = title
      if (!moduleSetSummary.aboutIsManuallyEdited) moduleSetSummary.about = about
    },
    onBack() {
      this.$router.push('/home')
    },
    onNext() {
      if (this.preventNext) return
      this.createMtg()
    },
    async createMtg() {
      this.showNowLoading = true

      const user = await database.userCollection().findById(this.uid)

      const setSummary = this.$refs.set_summary
      const editMember = this.$refs.edit_member
      const setCondition = this.$refs.set_condition
      const selectMeetinRoomType = this.$refs.select_meeting_room_type

      if (!editMember.getMember()[0]) {
        alert('メンバーを入力してください')
        this.showNowLoading = false
        return
      }

      let meeting = {
        title: setSummary.title,
        about: setSummary.about || '',
        attendee: editMember.getMember().map((e) => {
          e.responsed = false
          return e
        }),
        // only for query of firestore array-contain
        attendeeEmail: editMember
          .getMember()
          .filter((e) => e.email && e.email !== '')
          .map((e) => e.email),
        duration: setCondition.duration,
        timeRange: setCondition.timeRange,
        dateRange: setCondition.dateRange.value
          ? setCondition.dateRange.value
          : setCondition.dateRange,
        dayOfWeek: setCondition.dayOfWeek.filter((e) => e.active).map((e) => e.day),
        startedAt: new Date(),
        status: 'adjusting',
        adjustmentPolicy: '01-unanimous',
        createdBy: this.uid,
        createdAt: new Date(),
        timeZone: 'Asia/Tokyo',
        sentNotiAfter24h: false,
        selectMeetinRoomType: selectMeetinRoomType.meetingURLType
      }
      if (this.$route.params.meetingIdToTakeOver)
        meeting.baseMeeting = this.$route.params.meetingIdToTakeOver
      // 自分を最後に追加
      meeting.attendee.push({
        name: user.name,
        email: user.email,
        telNo: '', // TO DO: 電話番号が設定されていたら追加する
        responsed: false
      })
      meeting.attendeeEmail.push(user.email)

      if (meeting.attendee.length <= 1) {
        alert('参加者を入力してください')
        this.showNowLoading = false
        return
      }

      const meetingDoc = await database.meetingCollection().add(meeting)

      // TO DO: email抜きの名前だけでもアドレスに追加するようにする
      // const newContacts = editMember.getNewContacts()
      // if (newContacts[0]) {
      //   for (let i=0; i < newContacts.length; i++) {
      //     database.userCollection().addMemberToContact(this.uid, {
      //       name: newContacts[i].name,
      //       email: newContacts[i].email,
      //       createdBy: this.uid,
      //       createdAt: new Date()
      //     })
      //   }
      // }

      this.showNowLoading = false

      // eslint-disable-next-line
      this.$root.$emit('click_proceed_adjustment_btn', { 
        message: 'click_create_meeting_btn',
        option: {
          meetingId: meetingDoc.id,
          conditionTemplate: setCondition.currentConditionTemplate,
          venue: selectMeetinRoomType.meetingURLType
        }
      })

      this.$router.replace(
        `/create-meeting/send-attendee-events/${meetingDoc.id}/${setCondition.generateSlotsWith}`
      )
    },
    setPreventNext(preventNext) {
      this.preventNext = preventNext
    }
  }
}
</script>
